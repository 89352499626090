import React from "react";
import University from "./University";
import { getUniversities } from "../api/CourseAPI";

export default function UniversitiesList({ updateSelectedItem }) {
  const [universities, setUniversities] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const universities = await getUniversities();
      setUniversities(universities);
    }
    fetchData();
  }, [setUniversities]);

  return (
    <div>
      {universities.map((uni) => {
        return (
          <div key={uni}>
            <University
              university={uni}
              updateSelectedItem={updateSelectedItem}
            />
          </div>
        );
      })}
    </div>
  );
}
