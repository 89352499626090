import React from "react";
import Typography from "@material-ui/core/Typography";

export default function TableHeader({ selectedItem, searchCourseQuery }) {
  const UCCHeadline =
    "The UCC catalogue contains a list of study programmes. For information about courses within the study programme, please visit the UCC website.";
  const UCC = "University College Cork";

  if (selectedItem === null) {
    return (
      <Typography variant="h5" align="left" paragraph="true" noWrap>
        Courses related to {searchCourseQuery}
      </Typography>
    );
  } else {
    return (
      <>
        <Typography variant="h5" align="left" paragraph="true" noWrap>
          {selectedItem?.faculty} ({selectedItem?.university})
        </Typography>
        {selectedItem?.faculty === UCC ? (
          <Typography variant="subtitle2" align="left" paragraph="true" noWrap>
            {UCCHeadline}
          </Typography>
        ) : null}
      </>
    );
  }
}
