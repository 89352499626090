import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Faculty from "./Faculty";
import { getFacultiesForUniversity } from "../api/CourseAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 1000,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FacultyList({ university, updateSelectedItem }) {
  const classes = useStyles();
  const [faculties, setFaculties] = React.useState([]);

  React.useEffect(() => {
    async function fetchData(university) {
      const faculties = await getFacultiesForUniversity(university);
      setFaculties(faculties);
    }
    fetchData(university);
  }, [setFaculties, university]);

  return (
    <List className={classes.root}>
      {faculties.map((fac) => {
        return (
          <div key={fac}>
            <Faculty
              university={university}
              faculty={fac}
              updateSelectedItem={updateSelectedItem}
            />
          </div>
        );
      })}
    </List>
  );
}
