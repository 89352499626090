import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TablePagination from "@material-ui/core/TablePagination";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const UCC = "University College Cork";

function Row({ row, index, faculty }) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const NA =
    "Please visit the website of the Faculty.";

    const [isMobile, setIsMobile] = useState(false)
 
    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 576) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
    }
    
    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize)
    })
    
  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        hover
        role="checkbox"
        tabIndex={-1}
        style={
          index % 2
            ? { background: "rgb(255, 58, 33, 0.1)" }
            : { background: "white" }
        }
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.courseName}
        </TableCell>
        <TableCell align="right">{row.ECTScredit || NA}</TableCell>
        {faculty === UCC ? (
          <TableCell align="right">
            <a href={row.webpage} target="_blank" rel="noreferrer noopener">
              {row.webpage || NA}
            </a>
          </TableCell>
        ) : (
          <TableCell align="right">
            {row.semesterValue?.toUpperCase() || NA}
          </TableCell>
        )}
        {faculty === undefined ? (
          <TableCell align="right">{row.university}</TableCell>
        ) : (
          ""
        )}
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} className="open-section">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell colspan={5}>
                      <b>Description</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>{row.description || NA}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={faculty === undefined ? 2 : 5}>
                      <b>Webpage</b>
                    </TableCell>
                    {faculty === undefined ? (
                      <TableCell colSpan={3}>
                        <b>Faculty</b>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={faculty === undefined ? 2 : 5}>
                      <a
                        href={row.webpage}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {row.webpage || NA}
                      </a>
                    </TableCell>
                    {faculty === undefined ? (
                      <TableCell colSpan={3}>{row.faculty}</TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                  {isMobile ? (
                  <>
                    <TableRow>
                      <TableCell>
                        <b>Study programme</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.studyProgramme || NA}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Semester of study</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.semester || NA}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Course status</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.courseStatusValue || NA}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Offered</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.offered || NA}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Planned</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.planned || NA}</TableCell>
                    </TableRow>
                  </>
                  ) : (
                    <>
                    <TableRow>
                      <TableCell>
                        <b>Study programme</b>
                      </TableCell>
                      <TableCell>
                        <b>Semester of study</b>
                      </TableCell>
                      <TableCell>
                        <b>Course status</b>
                      </TableCell>
                      <TableCell>
                        <b>Offered</b>
                      </TableCell>
                      <TableCell>
                        <b>Planned</b>
                      </TableCell>
                      </TableRow>
                    <TableRow>
                      <TableCell>{row.studyProgramme || NA}</TableCell>
                      <TableCell>{row.semester || NA}</TableCell>
                      <TableCell>{row.courseStatusValue || NA}</TableCell>
                      <TableCell>{row.offered || NA}</TableCell>
                      <TableCell>{row.planned || NA}</TableCell>
                    </TableRow>
                  </>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ rows, faculty, fuzzySearch }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [prevFaculty, setFaculty] = React.useState("");
  const [prevFuzzySearch, setFuzzySearch] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [sortConfig, setSortConfig] = React.useState({
    by: "courseName",
    order: "asc",
  });

  if (prevFaculty !== faculty || prevFuzzySearch !== fuzzySearch) {
    if (prevFuzzySearch !== fuzzySearch) {
      setSortConfig({ by: "", order: "asc" });
    }

    setPage(0);
    setFaculty(faculty);
    setFuzzySearch(fuzzySearch);
  }

  const handleChangeSortConfig = (param) => {
    if (param !== sortConfig.by) {
      setSortConfig({ by: param, order: "asc" });
    } else {
      let order = sortConfig.order === "asc" ? "desc" : "asc";
      setSortConfig({ by: param, order: order });
    }
  };

  const getSortOrder = (param) => {
    if (param !== sortConfig.by) {
      return null;
    } else {
      return sortConfig.order === "asc" ? (
        <KeyboardArrowDownRoundedIcon />
      ) : (
        <KeyboardArrowUpRoundedIcon />
      );
    }
  };

  const sortByConfig = () => {
    let key = sortConfig.by;
    if (sortConfig.order === "asc") {
      return function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        } else if (a[key] < b[key]) {
          return -1;
        } else {
          return 0;
        }
      };
    } else {
      return function (a, b) {
        if (a[key] > b[key]) {
          return -1;
        } else if (a[key] < b[key]) {
          return 1;
        } else {
          return 0;
        }
      };
    }
  };

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell onClick={() => handleChangeSortConfig("courseName")}>
                <span>
                  Course name / Study programme{getSortOrder("courseName")}
                </span>
              </TableCell>
              <TableCell
                align="right"
                onClick={() => handleChangeSortConfig("ECTScredit")}
              >
                <span>ECTS credit{getSortOrder("ECTScredit")}</span>
              </TableCell>
              {faculty === UCC ? (
                <TableCell align="right">
                  <span>Webpage</span>
                </TableCell>
              ) : (
                <TableCell
                  align="right"
                  onClick={() => handleChangeSortConfig("semesterValue")}
                >
                  <span>Semester{getSortOrder("semesterValue")}</span>
                </TableCell>
              )}
              {faculty === undefined ? (
                <TableCell
                  align="right"
                  onClick={() => handleChangeSortConfig("university")}
                >
                  <span>University{getSortOrder("university")}</span>
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(sortConfig.by !== "" ? rows.sort(sortByConfig()) : rows)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={row._id} row={row} index={index} faculty={faculty} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
