import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnRight, faBookOpen } from "@fortawesome/free-solid-svg-icons";

const logo = "./faviconUnic.svg";
const background = "./coverImg.svg";

const imagesPool = [
  { src: "./uni_logo/koc_logo.png",
    url: "https://www.unic.eu/en/universities/koc-university",
    alt: "Koç University"
  },
  { src: "./uni_logo/cork_logo.png",
    url: "https://www.unic.eu/en/universities/university-college-cork",
    alt: "University College Cork"
  },
  { src: "./uni_logo/erasmus_logo.png",
    url: "https://www.unic.eu/en/universities/erasmus-university",
    alt: "Erasmus University Rotterdam"
  },
  { src: "./uni_logo/deusto_logo.png",
    url: "https://www.unic.eu/en/universities/university-of-deusto",
    alt: "University of Deusto"
  },
  { src: "./uni_logo/liege_logo.png",
    url: "https://www.unic.eu/en/universities/university-of-liege",
    alt: "University of Liège"
  },
  { src: "./uni_logo/ruhr_logo.png",
    url: "https://www.unic.eu/en/universities/ruhr-university",
    alt: "Ruhr-University Bochum"
  },
  { src: "./uni_logo/oulu_logo.png" ,
    url: "https://www.unic.eu/en/universities/university-of-oulu",
    alt: "University of Oulu"
  },
  { src: "./uni_logo/zagreb_logo.png",
    url: "https://www.unic.eu/en/universities/university-of-zagreb",
    alt: "University of Zagreb"
  },
];

const comingSoonPool = [
  { src: "./uni_logo/coming_soon/malmo_logo.svg",
    url: "https://mau.se/en/",
    alt: "Malmö University"
  },
  {
    src: "./uni_logo/coming_soon/lodz_logo.jpg",
    url: "https://www.uni.lodz.pl/",
    alt: "University of Lodz"
  }
]

export default function Home(props) {
  return (
    <div>
      <Box className="coverBox">
        <div className="coverImg" alt="Cover image">
          <div className="left" style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${background})`}}>
            <img src={logo} className="logo" alt="logo"></img>
          </div>
          <div className="middle">

          </div>
          <div className="right">
            <h1>UNIC</h1>
            <h2>EUROPEAN UNIVERSITY</h2>
            <button className="red-button" onClick={props.onClickHandler}
>
              <span>View courses</span>
              <FontAwesomeIcon icon={faArrowTurnRight} className="arrow"></FontAwesomeIcon>
            </button>
          </div>
        </div>
      </Box>
      <Box px={12} pt={8} pb={10} className="flex text-box">
        <FontAwesomeIcon icon={faBookOpen} className="fa-icon" />
        <Typography variant="h6" align="center" paragraph className="info-text">
          A multidisciplinary course catalogue contains courses taught in
          English within the UNIC universities’ study programs. The aim is to
          achieve inter-university mobility of all UNIC students by enabling
          customized learning experiences. The catalogue is updated annually.
          Students attending one of the UNIC universities are able to explore a
          wide variety of courses in nearly all disciplines. The courses may be
          either virtual or on-site as part of UNIC inter-university mobility.
        </Typography>
      </Box>
      <div className="logo-gallery">
        {imagesPool.map((imgSrc, index) => (
          <a href={imgSrc.url} target="_blank" rel="noreferrer">
            <img src={imgSrc.src} key={index} alt={imgSrc.alt} />
          </a>
        ))}
      </div>
      <div className="coming-soon-section">
      <h1>Coming soon</h1>
      <div className="logo-gallery coming-soon">
        {comingSoonPool.map((imgSrc, index) => (
          <a href={imgSrc.url} target="_blank" rel="noreferrer">
            <img src={imgSrc.src} key={index} alt={imgSrc.alt} />
          </a>
        ))}
      </div>
      </div>
    </div>
  );
}
