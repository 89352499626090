import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getUniversities = async () => {
  try {
    const payload = `${API_URL}/uni`;
    const response = await axios.get(payload);
    return response?.data?.success ? response.data.data : [];
  } catch (error) {
    return [];
  }
};

export const getFacultiesForUniversity = async (university) => {
  try {
    const payload = `${API_URL}/uni/${university}/fac`;
    const response = await axios.get(payload);
    return response?.data?.success ? response.data.data : [];
  } catch (error) {
    return [];
  }
};

export const getCoursesForFaculty = async (university, faculty) => {
  try {
    const payload = `${API_URL}/uni/${university}/fac/${faculty}/course`;
    const response = await axios.get(payload);
    return response?.data?.success ? response.data.data : [];
  } catch (error) {
    return [];
  }
};

export const getCoursesFuzzySearch = async (courseNameFuzzy) => {
  try {
    const payload = `${API_URL}/search/course`;
    const params = { params: { name: courseNameFuzzy } };
    const response = await axios.get(payload, params);
    return response?.data?.success ? response.data.data : [];
  } catch (error) {
    return [];
  }
};
