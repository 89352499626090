import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import SchoolIcon from "@material-ui/icons/School";

const ListItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "rgb(255, 58, 33, 0.1)",
    },
  },
  selected: {},
})(MuiListItem);

export default function Faculty({ university, faculty, updateSelectedItem }) {
  var imgSrc = "./faculty_logos/" + university + "/" + String(faculty) + ".jpg";

  return (
    <ListItem
      button
      divider
      style={{ cursor: "pointer" }}
      onClick={() => updateSelectedItem({ university, faculty })}
    >
      <ListItemAvatar>
        <Avatar className="bg-white">
          <img src={imgSrc} alt={faculty}></img>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={faculty} />
    </ListItem>
  );
}
