import React from "react";
import clsx from "clsx";
import { alpha, makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";
import UniversitiesList from "./UniversitiesList";
import Home from "./Home";
import TableHeader from "./TableHeader";
import CoursesList from "./CoursesList";
import Button from "@material-ui/core/Button";

const logo = "./faviconUnic.svg";

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgb(255, 58, 33, 1)",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "16ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
  searchButton: {
    backgroundColor: theme.palette.common.white,
    color: "rgb(255, 58, 33, 1)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.8),
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [searchCourseQuery, setSearchCourseQuery] = React.useState(null);
  const [input, setInput] = React.useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const updateSelectedItem = (item) => {
    setSelectedItem(item);
    if (item !== null) {
      setInput("");
      setSearchCourseQuery(null);
    }
  };

  const searchCourses = () => {
    try {
      setSelectedItem(null);
      setOpen(false);

      const searchInput = input;
      setSearchCourseQuery(searchInput);
    } catch (err) {
      setSearchCourseQuery(null);
    }
  };

  const clearHomePage = () => {
    setSelectedItem(null);
    setInput("");
    setSearchCourseQuery(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link
              href="#"
              onClick={() => {
                clearHomePage();
              }}
              color="inherit"
            >
              UNIC list of courses
            </Link>
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={input}
              inputProps={{ "aria-label": "search" }}
              onKeyPress={(e) => (e.key === "Enter" ? searchCourses() : null)}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <div>
            <Button
              classes={{ root: classes.searchButton }}
              disableElevation
              onClick={searchCourses}
            >
              <b>Search</b>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <a href="/" className="drawer-header-title">
            <img src={logo} alt="UNIC courses"></img>
            <span className="drawer-header-title">UNIC courses</span>
          </a>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <UniversitiesList updateSelectedItem={updateSelectedItem} />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {selectedItem === null && searchCourseQuery === null ? (
          <Home onClickHandler={handleDrawerOpen}/>
        ) : (
          <div>
            <Box px={8}>
              <TableHeader
                selectedItem={selectedItem}
                searchCourseQuery={searchCourseQuery}
              />
              <CoursesList
                university={selectedItem?.university}
                faculty={selectedItem?.faculty}
                searchCourseQuery={searchCourseQuery}
              />
            </Box>
          </div>
        )}
      </main>
    </div>
  );
}
