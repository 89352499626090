import React from "react";
import CollapsibleTable from "./CollapsibleTable";
import { getCoursesForFaculty, getCoursesFuzzySearch } from "../api/CourseAPI";

export default function CoursesList({
  university,
  faculty,
  searchCourseQuery,
}) {
  const [courses, setCourses] = React.useState([]);

  React.useEffect(() => {
    async function fetchData(university, faculty, searchCourseQuery) {
      let courses;
      if (university !== undefined && faculty !== undefined) {
        courses = await getCoursesForFaculty(university, faculty);
      } else {
        courses = await getCoursesFuzzySearch(searchCourseQuery);
      }
      setCourses([]);
      setCourses(courses);
    }
    fetchData(university, faculty, searchCourseQuery);
  }, [setCourses, university, faculty, searchCourseQuery]);

  return (
    <CollapsibleTable
      rows={courses}
      faculty={faculty}
      fuzzySearch={searchCourseQuery}
    />
  );
}
